import Heading from "Components/Heading";
import React, { useCallback, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BiSearch, BiExport, BiLike, BiCheckCircle } from "react-icons/bi";
import Footer from "Components/Footer";
import DataTable from "Components/CustomTable";

import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import SearchForm from "Components/SearchForm";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import ReactDatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import OperationsModal from "./components/OperationsModal";
import { set } from "date-fns";
import RescheduleModal from "./components/RescheduleModal";
import CustomDatePicker from "Components/CustomDatePicker";
import { wrapLastTextInBracketsWithStrong } from "Utils/utils";

const RescheduleRevoke = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rescheduleModalShow, setRescheduleModalShow] = useState(false);
  const [operationsModalShow, setOperationsModalShow] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [rescheduleDate, setRescheduleDate] = useState(null);

  const handleSelectionChange = useCallback((selectedIds) => {
    setSelectedIds(selectedIds);
  }, []);

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) =>
        wrapLastTextInBracketsWithStrong(row.original.location),
    },
    { accessor: "entered", Header: "Entered" },

    {
      accessor: "assigntoo",
      Header: "Assigned To",
      Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    { accessor: "due", Header: "Due" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
    {
      accessor: "isAssignPerTicket",
      Header: "Assign Per Ticket",
      Cell: ({ row }) => (
        <p>
          {row.original.isAssignPerTicket ? (
            <BiLike color="green" size={30} />
          ) : (
            <BiLike color="red" size={30} />
          )}
        </p>
      ),
    },
  ];

  /**
   * Submit Handler for office Clear
   */
  // const officeClearOrClose = async (type) => {
  //   if (!selectedIds && !selectedIds[0]) {
  //     toast.error("No Tickets Selected");
  //     return;
  //   }
  //   if (type === "office_clear") {
  //     const res = await TicketAPIs.officeClear({ ticketId: selectedIds });
  //     if (res) {
  //       toast.success("Office Clear Successfully");
  //     }
  //   } else if (type === "close") {
  //     const close = await TicketAPIs.closeTickets({ ticketId: selectedIds });
  //     if (close) {
  //       toast.success("Tickets Closed Successfully");
  //     }
  //   }
  //   setSelectedIds([]);
  //   setSearchedData([]);
  //   setIsLoading(false);
  // };

  const rescheduleTicket = async () => {
    if (!rescheduleDate) {
      toast.error("Please Select Reschedule Date");
      return;
    }
    if (selectedIds.length === 0) {
      toast.error("No Tickets Selected");
      return;
    }
    setRescheduleModalShow(true);
    return;
    setIsLoading(true);
    const res = await TicketAPIs.rescheduleTicket({
      ticketId: selectedIds,
      rescheduleDate,
    });
    if (res) {
      toast.success("Tickets Rescheduled Successfully");
    }
    setRescheduleDate(null);
    setSelectedIds([]);
    setIsLoading(false);
  };

  const revokeTicket = async () => {
    if (selectedIds.length === 0) {
      toast.error("No Tickets Selected");
      return;
    }
    setIsLoading(true);
    const res = await TicketAPIs.revokeTicket({
      ticketId: selectedIds,
    });
    if (res) {
      toast.success("Tickets Revoked Successfully");
    }
    setSelectedIds([]);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader isLoading={isLoading} />}
      <section>
        <Heading text={"Reschedule Revoke Ticket"} btn />

        <Card>
          <SearchForm
            setSearchedData={setSearchedData}
            setIsLoading={setIsLoading}
            loadDataOnMount={true}
            filters={{ isOfficeClear: false }}
            hiddenElements={["cancelDate"]}
          />
          <Row className="mb-5 d-flex align-items-end">
            <Col md={6}>
              <Form.Group className="form-group mb-0">
                <Form.Label>Start Date</Form.Label>
                <div className="datepicker_box">
                  <CustomDatePicker
                    selected={rescheduleDate}
                    onSelect={setRescheduleDate}
                    value={rescheduleDate}
                    dateFormat="MM/dd/yyyy"
                    minDate={new Date()}
                  />
                  <span>
                    <MdDateRange />
                  </span>
                </div>
              </Form.Group>
            </Col>
            <Col md={6} className="mt-5 mt-md-0">
              {/* <Button className="me-3" onClick={rescheduleTicket}>
                <BiCheckCircle />
                Rescheduleeeeee
              </Button> */}
              <Button className="me-3" onClick={revokeTicket}>
                <BiCheckCircle />
                Revoke
              </Button>
              <Button
                onClick={() => {
                  if (selectedIds.length === 0) {
                    toast.error("No Tickets Selected");
                    return;
                  }
                  setOperationsModalShow(true);
                }}
              >
                <BiCheckCircle />
                Operation Logs
              </Button>
            </Col>
          </Row>
          <SampleDataTable
            columns={columns}
            data={searchedData}
            onSelectionChange={handleSelectionChange}
          />
          <OperationsModal
            showModal={operationsModalShow}
            setShowModal={setOperationsModalShow}
            ticketIds={selectedIds}
            key={selectedIds}
            date={rescheduleDate}
          />
          <RescheduleModal
            showModal={rescheduleModalShow}
            setShowModal={setRescheduleModalShow}
            ticketIds={selectedIds}
            key={selectedIds}
            date={rescheduleDate}
          />
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default RescheduleRevoke;
