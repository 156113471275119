import React, { memo, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

import { capitalizeFirstLetter, formatDate } from "Helper/Converters";
import AuthAPIs from "../../APIs/auth";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import TicketTypesAPIs from "../../APIs/ticket-types";
import StationCodesAPIs from "../../APIs/station-code";
import RegionAPIs from "../../APIs/region";
import { toast } from "react-toastify";
import CustomDatePicker from "Components/CustomDatePicker";
import moment from "moment";

const COMPLETED_TICKETS_OPTIONS = [
  { value: "", label: "Select" },
  { label: "All", value: "all" },
  { label: "Incompleted", value: false },
  { label: "Completed", value: true },
];

const CANCEL_DATE_OPTIONS = [
  { label: "Canceled", value: "notNull" },
  { label: "Not Cancel", value: null },
];

const SearchForm = ({
  setSearchedData,
  setIsLoading,
  filters = {},
  loadDataOnMount = true,
  refresh,
  hiddenElements = [],
  from,
}) => {
  // const isMounted = useRef(false);

  const [checkedItems, setCheckedItems] = useState({});
  const [users, setUsers] = useState([]);
  const { userId, user } = useSelector((state) => state.auth);
  const { department } = useSelector((state) => state.auth);

  const [stationCodes, setStationCodes] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const [ticketTypes, setTicketTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(loadDataOnMount ? true : false);

  //this is for department=="locator"
  const [assigned, setAssigned] = useState(false);
  const [reassigned, setReassigned] = useState(false);

  const validationSchema = Yup.object().shape({
    ticketType: Yup.string(),
    ticketNo: Yup.string(),
    contractor_name: Yup.string(),
    digNearestIntersection: Yup.string(),
    city: Yup.string(),
    typeOfWork: Yup.string(),
  });
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  const dateType = [
    { value: "", label: "Select" },
    { value: "completedDate", label: "Completed Date" },
    { value: "entered_date", label: "Entered Date" },
    { value: "dueDate", label: "Due Date" },
    { value: "assignDate", label: "Assigned Date" },
    { value: "preCompletedDate", label: "Pre Complete Date" },
    { value: "cancelDate", label: "Cancelled Date" },
    // { value: "", label: "Cenceled Date" },
  ];

  const officeClearDateType = [
    { value: "", label: "Select" },
    { value: "completedDate", label: "Completed Date" },
    { value: "entered_date", label: "Entered Date" },
    { value: "dueDate", label: "Due Date" },
    { value: "assignDate", label: "Assigned Date" },
    { value: "cancelDate", label: "Cancelled Date" },
    // { value: "", label: "Cenceled Date" },
  ];

  const reAssignDateType = [
    { value: "", label: "Select" },
    { value: "completedDate", label: "Completed Date" },
    { value: "entered_date", label: "Entered Date" },
    { value: "dueDate", label: "Due Date" },
    { value: "assignDate", label: "Assigned Date" },
    { value: "CancelledDate", label: "Cancelled Date" },
  ];

  const assignDateType = [
    { value: "", label: "Select" },
    { value: "entered_date", label: "Entered Date" },
    { value: "dueDate", label: "Due Date" },
  ];

  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();
    setStationCodes(
      stationCodes?.data?.data
        ?.filter((item) => item.isFilterable)
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    );
  };

  const getAllTicketTypes = async () => {
    const types = await TicketTypesAPIs.getTicketType();

    const customArr = [{ value: "", label: "Select" }];
    if (types) {
      const arr = [...types.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setTicketTypes(customArr);
    }
  };

  const getAllRegions = async () => {
    const res = await RegionAPIs.getRegion();

    const customArr = [{ value: "", label: "Select" }];
    if (res) {
      const arr = [...res.data.data];
      arr?.forEach((item) => {
        const data = {
          value: `${item?.name}`,
          label: `${capitalizeFirstLetter(item?.name?.toLowerCase())} Region`,
        };
        customArr.push(data);
      });
      setRegions(customArr);
    }
  };

  const submitHandler = async (values) => {
    let validFilters = { ...filters };
    // if (isMounted.current) {
    //  setIsLoading(true);
    // }

    for (const key of Object.keys(values)) {
      if (values[key] !== "" && values[key] !== null) {
        validFilters[key] = values[key];
      }
    }
    if (values.dateType || values.fromDate) {
      if (!values.dateType || !values.fromDate) {
        toast.error("Please Select Date Type and Start Date atleast ");

        return;
      }
    }
    // validFilters = Object.entries(validFilters).filter(
    //   ([key, value]) => value !== "" && value !== null
    // );
    // validFilters = Object.fromEntries(validFilters);

    //if other than admin
    if (
      user.role === "user" &&
      department?.name != "admin" && department?.name != "manager" &&
      !validFilters.reAssignTo
    ) {
      validFilters.assignTo = userId;
      validFilters.reAssignTo = userId;
    }

    // if (validFilters.stationCodes && !(validFilters.stationCodes?.length > 0)) {
    //   delete validFilters.stationCodes; // Adjust based on your backend's expected format
    // }

    if (validFilters.dateType == "completedDate") {
      delete validFilters.completedDate; // Adjust based on your backend's expected format
    }

    if (validFilters.stationCodes?.length == 0) {
      delete validFilters.stationCodes;
    }

    if (values.fromDate)
      validFilters.fromDate = moment(values.fromDate).format("YYYY-MM-DD");

    if (values.toDate)
      validFilters.toDate = moment(values.toDate).format("YYYY-MM-DD");

    setCurrentFilters(validFilters); // Update current filters
    setLoading(true);
    const search = await TicketAPIs.search(validFilters);
    if (search) {
      const customArr = [];
      let filteredData = [...search.data.data];
      // Apply filters based on the provided criteria
      // Object.keys(filters).forEach((key) => {
      //   if (filters[key] === null) {
      //     // Filter out items where the key is null
      //     filteredData = filteredData.filter((item) => item[key] === null);
      //   } else if (filters[key] === "notNull") {
      //     // Filter out items where the key is not null
      //     filteredData = filteredData.filter((item) => item[key] !== null);
      //   } else if (filters[key] === false) {
      //     // Filter out items where the key is not null
      //     filteredData = filteredData.filter((item) => item[key] === false);
      //   } else if (filters[key] === true) {
      //     // Filter out items where the key is not null
      //     filteredData = filteredData.filter((item) => item[key] === true);
      //   } else if (filters[key] !== undefined) {
      //     // Filter out items based on specific value
      //     filteredData = filteredData.filter(
      //       (item) => item[key] === filters[key]
      //     );
      //   }
      // });


      // filteredData?.forEach((item) => {
      //   //   "DUCK",
      //   //   "SearchForm:filters:stationCodes",
      //   //   item?.stationCodes
      //   // );
      //   const data = {
      //     id: item?.id,
      //     ticket: item?.ticketNo,
      //     location: `${
      //       item?.digAddress != null || item?.digAddress !== undefined
      //         ? item?.digAddress
      //         : ""
      //     } (${item?.ticketType})`,
      //     assigntoo: item?.assignTo?.firstName,
      //     assignUserID: item?.assignTo?.id,
      //     reassinged: item?.reAssignTo?.firstName,
      //     due: item?.workToBeginDate ? formatDate(item?.workToBeginDate) : "",
      //     code: item?.stationCodes,
      //     segmet: item?.digSegments != null ? item?.digSegments : "0",
      //     city: item?.city,
      //     lat: item?.locationLat,
      //     lng: item?.locationLng,
      //     preCompletedDate: item?.preCompletedDate
      //       ? formatDate(item?.preCompletedDate)
      //       : "",
      //     completedDate: item?.completedDate
      //       ? formatDate(item?.completedDate)
      //       : "",
      //     reassingndate: item?.reAssignDate
      //       ? formatDate(item?.reAssignDate)
      //       : "",
      //     closeDate: item?.closeDate ? formatDate(item?.closeDate) : "",
      //     entered: item?.createdAt ? formatDate(item?.createdAt) : "",
      //     createdAt: item?.createdAt ? formatDate(item?.createdAt) : "",
      //     assign: item?.assignDate ? formatDate(item?.assignDate) : "",
      //     isOfficeClear: item?.isOfficeClear,
      //     isAssignPerTicket: item?.isAssignPerTicket,
      //     contractor_name: item?.contractor_name || item?.contractorName,
      //     company_name: item?.company_name || item?.companyName,
      //     ticketType: item?.ticketType,
      //     priority: item?.priority,
      //     digAddress: item?.digAddress,
      //     typeOfWork: item?.typeOfWork,
      //     location: `${item?.digAddress ? item?.digAddress : ""} (${
      //       item?.region
      //     })
      //               ${item?.company_name || item?.companyName} ${
      //       item?.contractor_name || item?.contractorName
      //     } ${item?.digCity}
      //               (${item?.ticketType}) `,
      //   };
      //   customArr.push(data);
      // });
      setSearchedData(filteredData);
    } else {
      setSearchedData([]);
    }
    setLoading(false);

    //if (isMounted.current) {
    //setIsLoading(false);
    //}
  };

  /**
   * Get All Users.
   */
  const getUsers = async () => {
    // setIsLoading(true);
    const users = await AuthAPIs.getAllUsers();
    const customArr = [];
    if (users) {
      users.data?.data?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        if (item?.id !== userId) customArr.push(data);
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
    //setIsLoading(false);
  };

  useEffect(() => {
    // Perform initial data fetching
    const fetchData = async () => {
      return await Promise.all([
        getUsers(),
        getAllStationCodes(),
        getAllTicketTypes(),
        getAllRegions(),
      ]);
      // Additional initial data fetching logic here
    };

    fetchData().then(() => {
      // After fetching initial data, check if we should load data on mount
      if (loadDataOnMount) {

        // Since loadDataOnMount is true, submit the form with initial values
        // Assuming you might want to pass some default values to submitHandler
        // Adjust accordingly if your submitHandler needs specific parameters
        submitHandler({
          fromDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          toDate: new Date(),
          dateType: dateType[2].value,
          completedDate: COMPLETED_TICKETS_OPTIONS[1].value,
        });
      }
    });

    // Set isMounted to true to indicate component has mounted
    // isMounted.current = true;

    // return () => {
    //   // Cleanup setting isMounted to false when component unmounts
    //   isMounted.current = false;
    // };
  }, [loadDataOnMount, refresh]); // Ensure useEffect is called when loadDataOnMount changes

  return (
    <Formik
      onSubmit={(values) => {
        submitHandler(values);
      }}
      initialValues={{
        // ticketType: ticketType[0].label,
        ticketNo: "",
        contractor_name: "",
        company_name: "",
        digAddress: "",
        digNearestIntersection: "",
        city: "",
        assignTo: "",
        reAssignTo: "",
        typeOfWork: "",
        fromDate: "",
        toDate: "",
        dateType: dateType[0].value,
        stationCodes: [],
        completedDate: COMPLETED_TICKETS_OPTIONS[1].value,
        cancelDate: null,
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        resetForm,
      }) => (
        <Form
          className="dashboard-form mb-4"
          noValidate
          onSubmit={handleSubmit}
        >
          <Row>
            {!hiddenElements.includes("ticketNo") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Ticket Number</Form.Label>
                  <Form.Control
                    type="text"
                    name={"ticketNo"}
                    onChange={handleChange}
                    value={values.ticketNo}
                  />
                </Form.Group>
              </Col>
            )}
            {/* {department?.name != 'locator' && !hiddenElements.includes("assignTo") &&
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Assigned To</Form.Label>
                  <Select
                    options={users}
                    defaultValue={users[0]}
                    styles={customStyles}
                    value={users.find(
                      (opt) => opt.assignTo === values.assignTo
                    )}
                    onChange={(selectedOption) => {
                      handleChange("assignTo")(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
            } */}

            {/* <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Re-Assigned To</Form.Label>
                <Select
                  options={users}
                  defaultValue={users[0]}
                  styles={customStyles}
                  value={users.find(
                    (opt) => opt.reAssignTo === values.reAssignTo
                  )}
                  onChange={(selectedOption) => {
                    handleChange("reAssignTo")(selectedOption.value);
                  }}
                />
              </Form.Group>
            </Col> */}

            {!hiddenElements.includes("digAddress") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name={"digAddress"}
                    onChange={handleChange}
                    value={values.digAddress}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("digNearestIntersection") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Nearest Intersection</Form.Label>
                  <Form.Control
                    type="text"
                    name={"digNearestIntersection"}
                    onChange={handleChange}
                    value={values.digNearestIntersection}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("contractor_name") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Contractor</Form.Label>
                  <Form.Control
                    type="text"
                    name={"contractor_name"}
                    onChange={handleChange}
                    value={values.contractor_name}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("company_name") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name={"company_name"}
                    onChange={handleChange}
                    value={values.company_name}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("ticketType") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Ticket Type</Form.Label>
                  <Select
                    options={ticketTypes}
                    styles={customStyles}
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        "ticketType",
                        selectedOptions.label !== "Select"
                          ? selectedOptions.label
                          : ""
                      );
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.ticketType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("region") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Region</Form.Label>
                  <Select
                    options={regions}
                    styles={customStyles}
                    onChange={(selectedOptions) => {
                      setFieldValue("region", selectedOptions.value);
                    }}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.region}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}

            {window.location.pathname.includes("query-search") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Completed Tickets</Form.Label>
                  <Select
                    options={COMPLETED_TICKETS_OPTIONS}
                    styles={customStyles}
                    value={COMPLETED_TICKETS_OPTIONS.find(
                      (opt) => opt.value === values.completedDate
                    )}
                    onChange={(selectedOptions) => {
                      setFieldValue("completedDate", selectedOptions.value);
                    }}
                  />
                </Form.Group>
              </Col>
            )}

            {/* <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Complete/InComplete Text</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col> */}

            {!hiddenElements.includes("callerName") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name={"city"}
                    onChange={handleChange}
                    value={values.city}
                  />
                </Form.Group>
              </Col>
            )}

            {/* <Col md={4}>
              <Form.Group className="form-group">
                <Form.Label>Caller Name</Form.Label>
                <Form.Control type="text" />
              </Form.Group>
            </Col> */}
            {!hiddenElements.includes("typeOfWork") && (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label>Type Of Work</Form.Label>
                  <Form.Control
                    type="text"
                    name={"typeOfWork"}
                    onChange={handleChange}
                    value={values.typeOfWork}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("stationCodes") && (
              <Col md={12}>
                <Form.Group className="form-group">
                  <Form.Label>Station Codes</Form.Label>
                  <div className="checkboxHolder">
                    {stationCodes.map((option) => (
                      <div key={option.id} className={"form-check"}>
                        <Field
                          type="checkbox"
                          className={"form-check-input"}
                          name="stationCodes"
                          value={option?.id}
                          checked={values.stationCodes.includes(option?.id)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFieldValue(
                              "stationCodes",
                              isChecked
                                ? [...values.stationCodes, option?.id]
                                : values.stationCodes.filter(
                                  (item) => item !== option?.id
                                )
                            );
                          }}
                        />
                        <label key={option.id} className="form-check-label">
                          {" "}
                          {option?.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("dateType") && (
              <Col className="column">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Date Type</Form.Label>
                  <Select
                    options={
                      from == "Assign"
                        ? assignDateType
                        : from == "ReAssign"
                          ? reAssignDateType
                          : from == "officeClear"
                            ? officeClearDateType
                            : dateType
                    }
                    defaultValue={dateType[0]}
                    styles={customStyles}
                    value={dateType.find(
                      (opt) => opt.value === values.dateType
                    )}
                    onChange={(selectedOption) => {
                      handleChange("dateType")(selectedOption.value);
                    }}
                  />
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("fromDate") && (
              <Col className="column">
                <Form.Group className="form-group mb-0">
                  <Form.Label>Start Date</Form.Label>
                  <div className="datepicker_box">
                    <CustomDatePicker
                      defaultValue
                      selected={values.fromDate}
                      onSelect={(date) =>
                        handleChange({
                          target: { name: "fromDate", value: date },
                        })
                      }
                      value={values.fromDate}
                      dateFormat="MM/dd/yyyy"


                    />
                    <span>
                      <MdDateRange />
                    </span>
                  </div>
                </Form.Group>
              </Col>
            )}

            {!hiddenElements.includes("toDate") && (
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>End Date</Form.Label>
                  <div className="datepicker_box">
                    <CustomDatePicker
                      selected={values.toDate}
                      onSelect={(date) => {
                        handleChange({
                          target: { name: "toDate", value: date },
                        });
                      }}
                      value={values.toDate}
                      dateFormat="MM/dd/yyyy"
                    />
                    <span>
                      <MdDateRange />
                    </span>
                  </div>
                </Form.Group>
              </Col>
            )}

            {department?.name != "locator" && (
              <>
                {!hiddenElements.includes("assignTo") && (
                  <Row>
                    <Col md={4}>
                      <Form.Group className="form-group">
                        <Form.Label>
                          {from == "ReAssign" ? "Assigned From" : "Assigned To"}
                        </Form.Label>
                        <Select
                          options={users}
                          defaultValue={users[0]}
                          styles={customStyles}
                          value={users.find(
                            (opt) => opt.assignTo === values.assignTo
                          )}
                          onChange={(selectedOption) => {
                            handleChange("assignTo")(selectedOption.value);
                            handleChange("reAssignTo")(selectedOption.value);

                          }}
                        />
                      </Form.Group>
                    </Col>
                    {!hiddenElements.includes("reAssignTo") && (
                      <Col md={4}>
                        <Form.Group className="form-group">
                          <Form.Label>Re-Assigned To</Form.Label>
                          <Select
                            options={users}
                            defaultValue={users[0]}
                            styles={customStyles}
                            value={users.find(
                              (opt) => opt.assignTo === values.assignTo
                            )}
                            onChange={(selectedOption) => {
                              handleChange("reAssignTo")(selectedOption.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    {!hiddenElements.includes("cancelDate") && (
                      <Col md={4}>
                        <Form.Group className="form-group">
                          <Form.Label>Cancel / Not Cancel</Form.Label>
                          <Select
                            options={CANCEL_DATE_OPTIONS}
                            styles={customStyles}
                            onChange={(selectedOption) => {
                              setFieldValue("cancelDate", selectedOption.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                )}
              </>
            )}

            {department?.name == "locator" && (
              <Col md={12} className="d-flex flex-row mt-3">
                <>
                  <Form.Group className="me-4">
                    <Form.Check
                      type="checkbox"
                      label="Assigned To"
                      name="assignTo"
                      checked={values.assignTo}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleChange("assignTo")(isChecked ? userId : "");
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Re-Assigned To"
                      name="reAssignTo"
                      checked={values.reAssignTo}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleChange("reAssignTo")(isChecked ? userId : "");
                      }}
                    />
                  </Form.Group>
                </>
              </Col>
            )}

            <Col className="d-flex mb-4 column">
              <Button
                type="submit"
                style={{ minHeight: "44px", marginTop: "30px" }}
                disabled={loading}
              >
                <BiSearch />
                {loading ? "Loading..." : "Search"}
              </Button>
              <Button
                onClick={() => {
                  resetForm();
                  setSearchedData([]);
                  setFieldValue('fromDate', null)
                  setFieldValue('toDate', null)
                  setFieldValue('dateType', '')
                  setFieldValue('completedDate', '')
                }}
                style={{
                  minHeight: "44px",
                  marginTop: "30px",
                  marginLeft: "20px",
                  backgroundColor: "red",
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default memo(SearchForm);
