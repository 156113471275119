import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Col, Form, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dummyDoc from "../../Images/dummy-doc.webp";

import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";

const DocumentModal = (props) => {
  // State for email fields
  const [to, setTo] = useState(props?.to);
  const [cc, setCc] = useState(props?.cc);
  const [subject, setSubject] = useState(props.subject);
  const [body, setBody] = useState("");

  const [ticketFiles, setTicketFiles] = useState([]);

  const [isSending, setIsSending] = useState(false);

  // Handle change functions for each field
  const handleToChange = (e) => setTo(e.target.value);
  const handleCcChange = (e) => setCc("auroralocates@aurora.ca");
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleBodyChange = (e) => setBody(e.target.value);

  // Handle Send Email
  const handleSendEmail = async () => {
    // Map selectedImages to their URLs
    const attachments = selectedImages
      .map((imageId) => {
        const file = ticketFiles.find((file) => file.id === imageId);
        return file ? file.presignedUrl : null; // Assuming you want to send the presignedUrl
      })
      .filter((url) => url !== null); // Filter out any non-found files (just in case)


    // Here you can use the state values to send an email
    setIsSending(true);
    const sendmail = await TicketAPIs.sendEmail({
      ticketIds: props?.ticketIds,
      userId: props?.userId,
      to: to,
      ccEmail: cc,
      subject: subject.toString(),
      body: body,
      attachments: attachments,
    });

    if (sendmail) {
      toast.success("Email Sent!");
      setIsSending(false);
      props.onHide();
    } else {
      toast.error("Email Not Sent!");
      setIsSending(false);
    }
  };

  const [selectedImages, setSelectedImages] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleImageSelect = (imageId) => {
    const isSelected = selectedImages.includes(imageId);

    if (isSelected) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const getTicketFiles = async (ids) => {
    // if (ids) {
    const tickets = await TicketAPIs.ticketFiles({
      ticketId: props.ticketIds,
    });

    if (tickets) {
      // we are sorting on basis on createdAt at priorty but if createdAt are same then we are sorting on base of fileName last _1, _2, _45, basis
      if (Array.isArray(tickets?.data?.data)) {
        // const sortedData = tickets?.data?.data.sort((a, b) => {
        //     const dateComparison = new Date(a?.createdAt) - new Date(b?.createdAt);
        //     if (dateComparison !== 0) {
        //         return dateComparison;
        //     }
        //     const getNumber = (fileName) => parseInt(fileName.split('_').pop().split('.')[0], 10);
        //     return getNumber(a?.fileName) - getNumber(b?.fileName);
        // });
        const sortedData = tickets?.data?.data.sort((a, b) => a?.createdAt.localeCompare(b?.createdAt));
        setTicketFiles(sortedData);
    }
    }
    // }
  };

  useEffect(() => {
    setSubject(props.subject);
    setTo(props.to);
    // setCc(props.cc);
    setCc("auroralocates@aurora.ca")

    setBody(props.body);
    getTicketFiles(props.subject);
  }, [props.subject]);

  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6 className="mb-0">Send Documents to Contractor</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="align-items-center mb-1">
            <Col sm="2">
              <b>To:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="email"
                  placeholder="example@gmail.com"
                  value={to}
                  onChange={handleToChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center mb-1">
            <Col sm="2">
              <b>CC:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="email"
                  value={cc}
                  onChange={handleCcChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center mb-1">
            <Col sm="2">
              <b>Subject:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="text"
                  placeholder="Documents for Ticket No."
                  value={subject}
                  onChange={handleSubjectChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-1">
            <Col sm="2">
              <b>Body:</b>
            </Col>
            <Col sm="10">
              <Form.Group className="from-group">
                <Form.Control
                  type="text"
                  placeholder=""
                  as={"textarea"}
                  rows={5}
                  value={body}
                  onChange={handleBodyChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
        {ticketFiles.map((item) => (
          <div key={item.id} className=" slider-box">
            {item.name.endsWith(".png") ||
            item.name.endsWith(".PNG") ||
            item.name.endsWith(".jpg") ||
            item.name.endsWith(".jpeg") ? (
              <React.Fragment>
                <Form.Check
                  type="checkbox"
                  className="mb-2"
                  label={`${item.name}`}
                  checked={selectedImages.includes(item.id)}
                  onChange={() => handleImageSelect(item.id)}
                />
                <div className="img-box" style={{ width: "300px" }}>
                  <img
                    src={item.presignedUrl}
                    alt={`img ${item.name}`}
                    style={{
                      height: "300px",
                      width: "300px",
                      objectFit: "contain",
                      objectPosition: "left",
                    }}
                  />
                </div>
              </React.Fragment>
            ) : (
              <div className="img-box">
                <Form.Check
                  type="checkbox"
                  className="mb-2"
                  label={`Doc ${item.id}`}
                  checked={selectedImages.includes(item.id)}
                  onChange={() => handleImageSelect(item.id)}
                />
                <img
                  src={dummyDoc}
                  alt={`pdf ${item.id}`}
                  style={{
                    height: "200px",
                    margin: "auto",
                    borderRadius: "18px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={isSending} onClick={handleSendEmail}>
          {`${isSending ? "Sending..." : "Send Email"}`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentModal;
