import Heading from "Components/Heading";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TicketAPIs from "../../APIs/ticket";
import Loader from "Components/Loader";
import AuthAPIs from "../../APIs/auth";
import SampleDataTable from "Components/DataTables";
import MapBox from "Components/MapBox";
import SearchForm from "Components/SearchForm";
import { wrapLastTextInBracketsWithStrong } from "Utils/utils";

const ReassignByMap = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPolygonEnabled, setIsPolygonEnabled] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [stats, setStats] = useState();

  const [dummy, setDummy] = useState([]);

  const customStyles = useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        minHeight: "44px",
      }),
    }),
    []
  );

  const handleSelectedLocations = (locations) => {
    setSelectedLocations((prevState) => {

      // Create a copy of the current state
      let updatedLocations = [...prevState];

      // Iterate over the passed locations
      locations.forEach((location) => {
        const locationIndex = prevState.findIndex(
          (item) => item?.ticketNo === location?.ticketNo
        );

        if (locationIndex === -1) {
          // Location is not in the array, add it
          updatedLocations.push(location);
        } else {
          // Location is already in the array, remove it (if you want toggle behavior)
          updatedLocations.splice(locationIndex, 1);
        }
      });

      return updatedLocations;
    });
  };

  const handleSelectionChange = (selectedIds) => {
    if (isPolygonEnabled) {
      setSelectedIds(selectedIds);
    }
  };

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
          className={`badge ${row.original?.cancelDate && "custom-badge"} ${
            row.original.ticketType == "EMERGENCY" ||
            row.original.priority == "EMERGENCY"
              ? "emergency"
              : row.original.ticketType == "MULTIPLE" ||
                row.original.priority == "MULTIPLE"
              ? "multiple"
              : row.original.ticketType == "PRIORITY" ||
                row.original.priority == "PRIORITY"
              ? "priority"
              : row.original.ticketType == "PROJECT WORK" ||
                row.original.priority == "PROJECT WORK"
              ? "projectWork"
              : row.original.ticketType == "STANDARD" ||
                row.original.priority == "STANDARD"
              ? "standard"
              : "default"
          }`}
        >
          {row.original.ticketNo}
          {row.original?.cancelDate && <span className="cancel-btn"></span>}
        </a>
      ),
    },
    {
      accessor: "location",
      Header: "Location",
      Cell: ({ row }) =>
        wrapLastTextInBracketsWithStrong(row.original.location),
    },
    { accessor: "entered", Header: "Entered" },

    { accessor: "assigntoo", Header: "Assigned To" },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    { accessor: "due", Header: "Due" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
  ];

  /**
   * Get All Users.
   */
  const getUsers = useCallback(async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [];
    if (users) {
      users.data?.data?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.firstName} ${item?.lastName}`,
        };
        if (item?.id !== userId) customArr.push(data);
      });
      customArr.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setUsers([{ value: "", label: "Select" }, ...customArr]);
    }
  }, [userId]);

  useEffect(() => {
    if (users.length === 0) getUsers();
  }, [getUsers, users.length]);

  useEffect(() => {
    const sortedTicket =
      searchedData?.map((item, index) => {
        return {
          id: index,
          ticketId: item?.id,
          ticketNo: item?.ticketNo,
          label: `
                <b>Ticket No:</b> ${item?.ticketNo || ""}<br>
                <b>Contractor Name:</b> ${item?.contractor_name || ""}<br>
                <b>Company Name:</b> ${item?.company_name || ""}<br>
                <b>Ticket Type:</b> ${item?.ticketType || ""}<br>
                <b>Dig Address:</b> ${item?.digAddress || ""}<br>
                <b>Assign To:</b> ${item?.assigntoo?.firstName || ""}<br>
                <b>Due Date:</b> ${item?.due || ""}`,
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
        };
      }) || [];
    setUnAssignedTicket(sortedTicket);
  }, [searchedData]);

  const handleSelectUnselectLocation = (index) => {
    setSelectedLocations((prev) =>
      prev.map((item, i) => {
        if (index == i) return { ...item, selected: !item.selected };
        return item;
      })
    );
  };

  /**
   * Assign Ticket Submit Handler.
   */
  const assignTicket = useCallback(async () => {
    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (selectedLocations.length === 0) {
      toast.error("No Tickets Selected.");
      return;
    }

    const ticketNosArray = selectedLocations.map(
      (innerArray) => innerArray.ticketId
    );


    const res = await TicketAPIs.assignReassignTicket({
      ticketId: ticketNosArray,
      userId: selectedUser,
      type: "re_assign",
    });

    if (res) {
      toast.success("Ticket Assigned Successfully");
      setTimeout(() => window.location.reload(), 1000);
    }
    setSelectedIds([]);
    setSelectedUser(null);
    setIsLoading(false);
  }, [selectedLocations, selectedUser]);

  const selectedLocationsLength = useMemo(() => {
    return selectedLocations.filter((item) => item.selected).length;
  }, [selectedLocations]);

  return (
    <div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Reassign Ticket By Map"} />

          <Card>
            <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{
                assignDate: "notNull",
                reAssignTo: null,
                isOfficeClear: false,
                cancelDate: null,
                removeOr: true,
                ass_reass_req: false,
              }}
              loadDataOnMount={true}
              hiddenElements={[
                "assignTo",
                "typeOfWork",
                "isCompletedTicketOnly",
                "cancelDate",
              ]}
              from={"ReAssign"}
            />
            <p style={{ fontSize: "14px", color: "#000" }}>
              Total Tickets Found{" "}
              <span style={{ color: "#757575", fontWeight: "bold" }}>
                {unAssignedTicket.length}
              </span>
            </p>
            <Row>
              <Col md={4}>
                <Row>
                  <Col sm={12} className="mb-2">
                    <Form.Group className="form-group">
                      <Form.Label>Assign To</Form.Label>
                      <Select
                        options={users}
                        styles={customStyles}
                        defaultValue={users[0]}
                        onChange={(selectedOption) => {
                          setSelectedUser(selectedOption.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="mb-3">
                    <Button
                      onClick={() => {
                        setSelectedLocations(
                          selectedLocations.filter((item) => !item.selected)
                        );
                      }}
                      variant="danger"
                      className="me-2"
                    >
                      Clear Selected ({selectedLocationsLength})
                    </Button>
                    <Button
                      disabled={!(selectedLocations.length > 0)}
                      onClick={() => {
                        setSelectedLocations([]);
                      }}
                      variant="danger"
                      className="me-2"
                    >
                      Clear All
                    </Button>
                    <Button
                      onClick={() => {
                        assignTicket();
                      }}
                    >
                      <AiOutlineCheckCircle className="" /> Dispatch
                    </Button>
                  </Col>

                  <Col className="mb-3" sm={12}>
                    <div className="dispatchBox">
                      {selectedLocations.map((ticket, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            if (!ticket?.selected)
                              handleSelectUnselectLocation(index);
                          }}
                          className="ticket-item"
                          style={ticket?.selected && { fontWeight: "bold" }}
                        >
                          {ticket?.ticketNo}
                        </p>
                      ))}
                    </div>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="form-group mb-0">
                      <Form.Check
                        type="checkbox"
                        id="Polygon Select"
                        label="Polygon Select"
                        name="Polygon Select"
                        checked={isPolygonEnabled}
                        onChange={() => {
                          if (isPolygonEnabled) {
                            // If it's currently enabled, then disable and reload.
                            setIsPolygonEnabled(false);
                            window.location.reload();
                          } else {
                            // Just toggle it to enabled without reloading.
                            setIsPolygonEnabled(true);
                          }
                        }}
                        className="include-check mb-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="Click to view ticket detail"
                        label="Click to view ticket detail"
                        name="Click to view ticket detail"
                        checked={isViewDetail}
                        onChange={() => {
                          setIsViewDetail(
                            (prevIsViewDetail) => !prevIsViewDetail
                          );
                        }}
                        className="include-check"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <MapBox
                  key={String(isViewDetail)}
                  locations={unAssignedTicket}
                  onSelectedLocations={handleSelectedLocations}
                  enablePolygon={isPolygonEnabled}
                  enableTicketDetail={isViewDetail}
                  // isMarkerClickable={false}
                />
              </Col>
            </Row>
            <Row>
              <SampleDataTable
                columns={columns}
                data={searchedData.filter(
                  (item) =>
                    selectedLocations.findIndex(
                      (it) => it.ticketId == item.id
                    ) > -1
                )}
                // onSelectionChange={handleSelectionChange}
              />
            </Row>
          </Card>
        </section>
      )}
    </div>
  );
};

export default ReassignByMap;
