import React, { useState, useCallback, memo, useEffect, useMemo } from "react";
import { Modal, Button, Tab, Tabs, Table, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import PayrollLogTableTab from "./tabs/PayrollLogTableTab";
import PayrollTicketSummaryTab from "./tabs/PayrollTicketSummaryTab";
import PayrollReimbursementTab from "./tabs/PayrollReimbursementTab";
import { FaStar } from "react-icons/fa";
import HourLogAPIs from "../../../APIs/hour-log";
import * as XLSX from "xlsx-js-style";
import TicketAPIs from "../../../APIs/ticket";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const STATION_CODE_MAPPING = [
  'One Station Code',
  'Two Station Codes',
  'Three Station Codes',
  'Four Station Codes',
  'Five Station Codes',
  'Six Station Codes',
  'Seven Station Codes',
  'Eight Station Codes',
  'Nine Station Codes',
  'Ten Station Codes',
  'Eleven Station Codes',
  'Twelve Station Codes',
  'Thirteen Station Codes',
  'Fourteen Station Codes',
  'Fifteen Station Codes',
]

const PerTicketReportModal = memo(({ show, handleClose, logDate, setLogDate, startDate, endDate, selectedUser, formatDate }) => {

  const user = useSelector((state) => state.auth);
  const [key, setKey] = useState("logTable");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([])
  const [standardInput, setStandardInput] = useState([''])
  const [additionalInput, setAdditionalInput] = useState([''])
  const [totalArray, setTotalArray] = useState([{ total_standard: 0, total_additional: 0 }])
  const [subtotalArray, setSubtotalArray] = useState([{ total_standard: 0, total_additional: 0 }])
  const [showGrandTotal, setShowGrandTotal] = useState(false)

  //dummy
  const dropdownOptions = [
    { label: "Work", value: "Work" },
    { label: "Lunch", value: "Lunch" },
  ];

  // Restrict future dates
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const handleDateChange = (date) => {
    setLogDate(date);
    setDatePickerVisible(false); // Hide DatePicker after selection
  };

  useEffect(() => {
    // Ensure the DatePicker is hidden when the modal is not shown
    if (!show) {
      setDatePickerVisible(false);
    }
  }, [show]);

  const separateOnTheBasisOfUnits = (data) => {
    let numberOfUnitFields = 0
    // const columns = data.reduce((acc, ticket) => {
    //   Object.keys(ticket?.ticketPreComplete || {}).forEach((key) => {
    //     if (key.includes('Unit') && !acc.includes(key))
    //       acc.push(key);
    //   });

    //   return acc
    // }, []);
    Object.entries(data[0]).forEach(([key, _]) => {
      if (key.includes('Unit'))
        numberOfUnitFields++
    })
    let formattedData = new Array(numberOfUnitFields).fill(null).map(item => [])
    data.forEach(item => {
      let count = 0
      Object.entries(item.ticketPreComplete || {}).forEach(([key, value]) => {
        if (key.includes('Unit') && value > 0)
          count++
      })
      if (count > 0)
        formattedData[count - 1].push(item)
    })
    return formattedData
  }

  const mergeOnSameStationCodes = (data) => {
    return data.map(item => {
      let obj = {}
      item.forEach(it => {
        if (it.stationCodes?.length > 0) {
          const stationCode = it.stationCodes.map(item => item.name).join(' ')
          obj[stationCode] = obj[stationCode] ? [...obj[stationCode], it] : [it]
        }
      })
      return obj
    })
  }

  const sumUpData = (data) => {
    return data.map((item, index) => {
      return Object.entries(item).map(([key, value]) => {
        let total_additional = 0
        value.forEach(it => {
          Object.entries(it.ticketPreComplete || {}).map(([innerKey, innerValue]) => {
            if (innerKey.includes('Unit')) {
              total_additional += Number(innerValue || 0)
            }
          })
        })
        return {
          station_code: key,
          total_standard: value.length,
          total_additional: total_additional - (value.length * (index + 1))
        }
      })
    })
  }

  const formatDateForExcel = () => {
    let formattedData = [[
      { v: "Station Code", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
      { v: "Total Standard", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
      { v: "Total Additional", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } }
    ]]
    data.forEach((item, index) => {
      if (item.length > 0) {
        formattedData.push(
          [],
          [
            { v: STATION_CODE_MAPPING[index], t: "s", s: { font: { bold: true } } },
          ]
        )
        item.forEach(innerItem => {
          formattedData.push([
            { v: innerItem.station_code },
            { v: innerItem.total_standard },
            { v: innerItem.total_additional },
          ])
        })
        formattedData.push(
          [
            { v: "Total", t: "s", s: { font: { bold: true } } },
            { v: totalArray[index].total_standard, s: { font: { bold: true } } },
            { v: totalArray[index].total_additional, s: { font: { bold: true } } },
          ],
          [
            { v: "Multiplier" },
            { v: standardInput[index] },
            { v: additionalInput[index] },
          ],
          [
            { v: "Sub Total", t: "s", s: { font: { bold: true } } },
            { v: `$${subtotalArray[index].total_standard}`, s: { font: { bold: true } } },
            { v: `$${subtotalArray[index].total_additional}`, s: { font: { bold: true } } },
          ]
        )
      }
    })
    formattedData.push(
      [],
      [
        { v: "Grand Total", t: "s", s: { font: { bold: true } } },
        { v: `$${subtotalArray.reduce((total, item) => total + item.total_standard, 0)}`, s: { font: { bold: true } } },
        { v: `$${subtotalArray.reduce((total, item) => total + item.total_additional, 0)}`, s: { font: { bold: true } } },
      ])
    return formattedData
  }

  const exportToExcel = (fileName) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(formatDateForExcel());

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const sendEmail = async () => {
    if (!showGrandTotal) {
      alert('Firstly, generate grand total by clicking on "Generate Grand Total" button')
      return
    }
    let body = `
    <table>
    <tr>
      <th>Station Code</th>
      <th>Total Standard</th>
      <th>Total Additional</th>
    </tr>
    ${data.map((item, index) => {
      return (
        `<tr></tr>
          <tr>
            <th>${STATION_CODE_MAPPING[index]}</th>
          </tr>
          ${item.map(innerItem => {
          return (
            `<tr>
              <td>${innerItem.station_code}</td>
              <td>${innerItem.total_standard}</td>
              <td>${innerItem.total_additional}</td>
              </tr>`
          )
        })}
          <tr>
          <td>Total</td>
          <td>${totalArray[index].total_standard}</td>
          <td>${totalArray[index].total_additional}</td>
          </tr>
          <tr>
          <td>Multiplier</td>
          <td>${standardInput[index]}</td>
          <td>${additionalInput[index]}</td>
          </tr>
          <tr>
          <td>Sub Total</td>
          <td>$${subtotalArray[index].total_standard}</td>
          <td>$${subtotalArray[index].total_additional}</td>
          </tr>
          `
      )
    })}
          <tr>
          <td>Grand Total</td>
          <td>$${subtotalArray.reduce((total, item) => total + item.total_standard, 0)}</td>
          <td>$${subtotalArray.reduce((total, item) => total + item.total_additional, 0)}</td>
          </tr>
    </table>`
    const sendmail = await TicketAPIs.sendEmail({
      ticketIds: [],
      userId: user?.id,
      to: 'codingghost06@gmail.com',
      subject: 'Per Ticket Report',
      body,
      attachments: [],
    });

    if (sendmail) {
      toast.success("Email Sent!")
    }
  }

  const getHoursLogTicketReport = async () => {
    if (!selectedUser?.value || !startDate || !endDate) {
      alert("Please select a user and both dates.");
      return;
    }

    setIsLoading(true);

    // Format dates
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    // Dummy API call structure, replace with your actual API call
    try {
      const response = await HourLogAPIs.getHoursLogTicketReport(
        selectedUser.value,
        formattedEndDate,
        formattedStartDate
      );
      if (response.data?.data?.length > 0) {
        // console.log("before formatting data?...", response.data.data)
        const filtered = response.data.data.filter(el => el.logType === "PER_TICKET");

        const data = (sumUpData(mergeOnSameStationCodes(separateOnTheBasisOfUnits(filtered))))
        // const data = (sumUpData(mergeOnSameStationCodes(separateOnTheBasisOfUnits(response?.data?.data))))

        setStandardInput(() => new Array(data.length).fill(''))
        setAdditionalInput(() => new Array(data.length).fill(''))
        setTotalArray(data.map(item => ({
          total_standard: item.reduce((total, it) => total + it.total_standard, 0),
          total_additional: item.reduce((total, it) => total + it.total_additional, 0),
        })))
        setSubtotalArray(data.map(item => ({
          total_standard: 0,
          total_additional: 0,
        })))
        setData(data)
      }
    } catch (error) {
      console.error("Failed to fetch hour logs ticket report:", error);
      // alert("An error occurred while fetching hour logs ticket report.");
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (show) {
      setData([])
      getHoursLogTicketReport()
      setShowGrandTotal(false)
    }
  }, [show])

  const checkGenerateBtnValidation = useMemo(() => {
    for (let i = 0; i < subtotalArray.length; i++) {
      if (data[i]?.length > 0 && !(subtotalArray[i].total_standard > 0)) {
        return false
      }
    }
    return true
  }, [subtotalArray, data])

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setDatePickerVisible(false); // Ensure DatePicker is hidden when modal is closed
      }}
      size="lg"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="text-center w-100" >
          Per Tickets Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row justify-content-around" >
          <span className="fw-bold">Locator: {selectedUser?.label}</span>
          <span className="fw-bold">Date Range: {format(new Date(endDate), "dd/MM/yyyy")} - {format(new Date(startDate), "dd/MM/yyyy")}</span>
        </div>
        {isLoading &&
          <div className="text-center my-3 fw-bold" >
            Loading...
          </div>
        }
        {data.map((item, index) => (
          item.length > 0 &&
          <Table striped bordered hover className="mt-3 per-ticket-report-custom-table">
            <thead>
              <tr>
                <th><span>STATION_CODE</span></th>
                <th><span>Total_Standard</span></th>
                <th><span>Total_Additional</span></th>
                <th />
              </tr>
            </thead>
            <tbody >
              <tr>
                <td><strong>{STATION_CODE_MAPPING[index]}</strong></td>
                <td />
                <td />
                <td />
              </tr>
              {item.map((it, index) => {
                return (
                  <tr key={index}>
                    <td>{it.station_code}</td>
                    <td>{it.total_standard}</td>
                    <td>{it.total_additional}</td>
                    <td />
                  </tr>
                );
              })}
              <tr>
                <td><strong>{'Total'}</strong></td>
                <td><strong>{totalArray[index].total_standard}</strong></td>
                <td><strong>{totalArray[index].total_additional}</strong></td>
                <td />
              </tr>
              <tr>
                <td>{'Multiplier'}</td>
                <td>
                  {showGrandTotal ?
                    standardInput[index] :
                    <input
                      type="number"
                      value={standardInput[index]}
                      onChange={e => {
                        standardInput[index] = e.target.value
                        setStandardInput([...standardInput])
                      }} />
                  }
                </td>
                <td>
                  {showGrandTotal ?
                    additionalInput[index] :
                    <input
                      type="number"
                      value={additionalInput[index]}
                      onChange={e => {
                        additionalInput[index] = e.target.value
                        setAdditionalInput([...additionalInput])
                      }} />
                  }
                </td>
                <td>
                  <span
                    className="p-2 rounded-1 d-flex justify-content-center"
                    style={{ backgroundColor: 'rgb(240,240,240)' }}
                    onClick={() => {
                      if (!(standardInput[index] > 0) || !(additionalInput[index] > 0)) {
                        alert('Please fill all fields!')
                      }
                      subtotalArray[index].total_standard = totalArray[index].total_standard * Number(standardInput[index])
                      subtotalArray[index].total_additional = totalArray[index].total_additional * Number(additionalInput[index])
                      setSubtotalArray([...subtotalArray])
                    }}
                  >
                    <FaStar style={{ color: '#ffcc00' }} />
                  </span>
                </td>
              </tr>
              <tr>
                <td><strong>{'Sub Total'}</strong></td>
                <td><strong>${subtotalArray[index].total_standard}</strong></td>
                <td><strong>${subtotalArray[index].total_additional}</strong></td>
                <td />
              </tr>
            </tbody>
          </Table>
        ))}
        {data.length > 0 && showGrandTotal &&
          <Table striped bordered hover className="mt-3 per-ticket-report-custom-table">
            <thead>
              <tr>
                <th></th>
                <th><span>Standard</span></th>
                <th><span>Additional</span></th>
                <th />
              </tr>
            </thead>
            <tbody >
              <tr>
                <td><strong>GrandTotal</strong></td>
                <td><strong>${subtotalArray.reduce((total, item) => total + item.total_standard, 0)}</strong></td>
                <td><strong>${subtotalArray.reduce((total, item) => total + item.total_additional, 0)}</strong></td>
                <td />
              </tr>
            </tbody>
          </Table>
        }
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-between" >
        {data.length > 0 && !showGrandTotal ?
          <Button
            onClick={() => {
              if (checkGenerateBtnValidation)
                setShowGrandTotal(true)
              else
                alert('Please fill all fields and generate subtotals by clicking on star icons.')
            }}>
            Generate Grand Total
          </Button> :
          <div />
        }
        <div>
          {data.length > 0 &&
            <>
              <Button onClick={() => {
                if (!showGrandTotal) {
                  alert('Firstly, generate grand total by clicking on "Generate Grand Total" button')
                  return
                }
                exportToExcel(`${selectedUser?.label} Per Tickets Report ${new Date(startDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) + "-" + new Date(endDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`)
              }}>Export</Button>
              <Button style={{ marginLeft: 10 }} onClick={sendEmail}>Send Email</Button>
            </>
          }
          <Button className="ms-3" onClick={handleClose}>Close</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});

export default PerTicketReportModal;
